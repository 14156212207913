import request from "../utils/request";

export function get_store_list(data: JSON){
    return request({
        url: "/getStoreList",
        method: "post",
        data: data
    });
}

export function get_store_detail(data: JSON){
    return request({
        url: "/getStoreDetail",
        method: "post",
        data: data
    });
}

export function save_store_detail(data: FormData){
    return request({
        url: "/saveStoreDetail",
        method: "post",
        data: data
    });
}

export function get_store_basic_info(data: JSON) {
    return request({
        url: "/getStoreBasicInfo",
        method: "post",
        data: data
    });
}

export function delete_store(data: JSON) {
    return request({
        url: "/deleteStore",
        method: "post",
        data: data
    });
}