<template>
    <div class="align-items-start p-3 text-start">
        <nav aria-label="breadcrumb">
        <ol class="breadcrumb text-dark m-auto">
            <li class="breadcrumb-item active" aria-current="page">店家資料管理《店家》</li>
            <li class="breadcrumb-item active" aria-current="page">店家資料維護</li>
        </ol>
        </nav>
        <hr class="w-100">
        <div class="col-12 row mb-3 mx-auto">
            <div class="col-12 accordion" id="accordion_filter">
                <div class="accordion-item">
                    <h2 class="accordion-header" id="heading_filter">
                        <button class="accordion-button bg-dark text-white" type="button" data-bs-toggle="collapse" data-bs-target="#collapse_filter" aria-expanded="false" aria-controls="collapse_filter">
                            搜尋條件
                        </button>
                    </h2>
                    <div id="collapse_filter" class="accordion-collapse show" aria-labelledby="heading_filter" data-bs-parent="#accordion_filter">
                        <div class="accordion-body col-12 row">
                            <div class="mb-3 col-lg-4">
                                <label for="store_code" class="form-label">店家編號</label>
                                <input type="text" class="form-control" id="store_code" v-model="display_query_data.store_code">
                            </div>
                            <div class="mb-3 col-lg-4">
                                <label for="tax_id" class="form-label">統一編號</label>
                                <input type="text" class="form-control" id="tax_id" v-model="display_query_data.tax_id">
                            </div>
                            <div class="mb-3 col-lg-4">
                                <label for="store_name" class="form-label">店家名稱</label>
                                <input type="text" class="form-control" id="store_name" v-model="display_query_data.store_name">
                            </div>
                            <div class="mb-3 col-lg-4">
                                <label for="store_type" class="form-label">店家類型</label>
                                <select id="store_type" class="form-select" v-model="display_query_data.store_type">
                                    <option value="">全部</option>
                                    <template v-for="item in store_type_option" :key="item">
                                        <option :value="item.value">{{ item.text }}</option>
                                    </template>
                                </select>
                            </div>
                            <div class="mb-3 col-lg-4">
                                <label for="store_status" class="form-label">狀態</label>
                                <select id="store_status" class="form-select" v-model="display_query_data.status">
                                    <option value="">全部</option>
                                    <option :value="0">停用</option>
                                    <option :value="1">啟用</option>
                                    <option :value="3">審核中</option>
                                    <option :value="4">未通過</option>
                                    <option :value="5">資料待補件</option>
                                </select>
                            </div>
                        </div>
                        <div class="col-12 row text-end mx-auto mb-3">
                            <div>
                                <button class="btn m-2 btn-primary" @click="search()">搜尋</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-12 row text-end mx-auto mb-3" v-if="role_list.store_management.role.split(',')[1] === '1'">
            <div>
                <router-link to="/StoreEdit">
                    <button class="btn m-2 btn-success">新增</button>
                </router-link>
            </div>
        </div>
        <div class="col-12 row text-end mx-auto mb-3">
            <div>
                <strong style="color: red;">編輯完店家基本資料，記得至商品資訊及優惠訊息繼續填寫</strong>
            </div>
        </div>
        <div class="col-12 row mx-auto mb-3">
            <div class="table-responsive container-lg col-12">
                <table class="table table-striped table-hover text-center align-middle">
                    <thead class="table-dark">
                        <tr>
                            <th class="col-2">店家編號</th>
                            <th class="col-1">統一編號</th>
                            <th class="col-4">店家名稱</th>
                            <th class="col-1">店家類型</th>
                            <th class="col-1">狀態</th>
                            <th class="col-3">功能</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="item in records" :key="item">
                            <td>{{ item.store_code }}</td>
                            <td>{{ item.tax_id }}</td>
                            <td>{{ item.store_name }}</td>
                            <td>{{ item.store_type }}</td>
                            <td>{{ item.store_status }}</td>
                            <td>
                                <span>
                                    <router-link :to="'/StoreProductList/'+ item.store_id">
                                        <button class="btn m-2 btn-info">商品資訊</button>
                                    </router-link>
                                    <router-link :to="'/StorePromotionList/'+ item.store_id">
                                        <button class="btn m-2 btn-info">優惠訊息</button>
                                    </router-link>
                                    <router-link :to="'/StoreEdit/'+ item.store_id" v-if="role_list.store_management.role.split(',')[2] === '1'">
                                        <button class="btn m-2 btn-warning">編輯</button>
                                    </router-link>
                                    <button class="btn m-2 btn-danger" @click.prevent="delete_store_data(item)" v-if="role_list.store_management.role.split(',')[3] === '1'">刪除</button>
                                    <button class="btn m-2 btn-dark" data-bs-toggle="modal" data-bs-target="#staticBackdrop" @click.prevent="store_qrcode(item)">店家QR Code</button>
                                </span>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
        <div class="col-12 row mx-auto mb-3">
            <nav aria-label="Page navigation example">
                <ul class="pagination justify-content-center">
                    <li class="page-item" :class="{'disabled': current_page === 1}" v-bind:style="{'cursor': current_page === 1 ? null : 'pointer'}">
                        <a class="page-link" aria-label="<<"
                            @click.prevent="onChangePage(1)">
                            <span aria-hidden="true">&laquo;</span>
                        </a>
                    </li>
                    <li class="page-item" :class="{'disabled': current_page === 1}" v-bind:style="{'cursor': current_page === 1 ? null : 'pointer'}">
                        <a class="page-link" aria-label="<"
                            @click.prevent="onChangePage(current_page - 1)">
                            <span aria-hidden="true">&lsaquo;</span>
                        </a>
                    </li>
                    <li class="page-item" v-for="page in links" :key="page" 
                        :class="{'active': page.active, 'disabled': page.label === '...'}" v-bind:style="{'cursor': page.active ? null : page.label === '...' ? null : 'pointer'}">
                        <a class="page-link" @click.prevent="onChangePage(page.label)">{{ page.label }}</a>
                    </li>
                    <li class="page-item" :class="{'disabled': current_page === last_page}" v-bind:style="{'cursor': current_page === last_page ? null : 'pointer'}">
                        <a class="page-link" aria-label=">"
                            @click.prevent="onChangePage(current_page + 1)">
                            <span aria-hidden="true">&rsaquo;</span>
                        </a>
                    </li>
                    <li class="page-item" :class="{'disabled': current_page === last_page}" v-bind:style="{'cursor': current_page === last_page ? null : 'pointer'}">
                        <a class="page-link" aria-label=">>"
                            @click.prevent="onChangePage(last_page)">
                            <span aria-hidden="true">&raquo;</span>
                        </a>
                    </li>
                </ul>
            </nav>
        </div>
        <div class="col-12 row mx-auto mb-3">
            <div class="input-group justify-content-center">
                <span class="text-vertical-center">前往第</span>
                <input type="number" class="form-control m-2" style="flex: 0 0 auto; width: auto;" id="current_page" v-model="current_page" :min="1" :max="9999999999">
                <span class="text-vertical-center">頁</span>
                <div>  
                    <button class="btn m-2 btn-primary" @click.prevent="onChangePage(current_page)">前往</button>
                </div>
            </div>
        </div>
        <div class="mb-3"></div>
        
        <!-- Modal -->
        <div class="modal fade" id="staticBackdrop" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
            <div class="modal-dialog modal-md">
                <div class="modal-content" style="color: #000000;">
                    <div class="modal-header text-center bg-dark text-white">
                        <h5 class="modal-title w-100" id="staticBackdropLabel">
                            店家QR Code
                        </h5>
                        <button type="button" class="btn-close btn-close-white" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div class="modal-body">
                        <div v-if="qrcode_display === true" class="col-12 row text-center mx-auto">
                            <div class="col-12 mb-3">
                                <div class="col-12">
                                    <!-- correctLevel 0: M(15%), 1: L(7%), 2: H(30%), 3: Q(25%) -->
                                    <vue-qr :text="qrcode_value" :size="300" :margin="2" :dotScale="1" :correctLevel="0" :callback="qrcode_content"></vue-qr>  
                                </div>
                                &nbsp;&nbsp;
                                <div class="col-12">
                                    <button class="btn btn-dark" v-on:click="download_image">下載圖片</button>
                                </div>
                            </div>
                        </div>
                        <div v-if="qrcode_display === false" class="col-12 row text-center mx-auto">
                            <div class="col-12 mb-3">
                                <div class="col-12">
                                    您的店家為非開立收據的店家，所以無此功能
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-danger" data-bs-dismiss="modal">關閉</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {
    get_store_list,
    delete_store
} from '../../api/store';
import { mapActions, mapGetters } from 'vuex';
import { get_store_type_option } from '../../api/data';

import vueQr from "vue-qr/src/packages/vue-qr.vue";
import FileSaver from "file-saver";
import Swal from 'sweetalert2';

export default {
    components: {
        vueQr
    },
    data() {
        return {
            last_page: 1,
            current_page: 1,
            links: [{
                label: "1",
                active: true,
            }],
            records: [],
            qrcode_value: "",
            qrcode_image_content: "",
            store_type_option: [],
            display_query_data: {
                store_code: "",
                tax_id: "",
                store_name: "",
                store_type: "",
                status: "",
            },
            query_data: {
                store_code: "",
                tax_id: "",
                store_name: "",
                store_type: "",
                status: "",
            },
            qrcode_image_file_name: "",
            delete_data: {},
            qrcode_display: false,
            page: 1,
        }
    },
    methods: {
        ...mapActions([
            'auth/UserLogout',
        ]),
        user_logout() {
            this['auth/UserLogout']().then(() => {
                this.$router.push({ name: 'Login' });
            }, () => {
                this.$router.push({ name: 'Login' });
            })
        },
        search(page = 1, page_change = false) {
            if (page_change === false) {
                this.query_data = JSON.parse(JSON.stringify(this.display_query_data));
            }
            this.page = page;
            get_store_list({page: page, query_data: this.query_data}).then(response => {
                if (response.data.success === false && response.data.check === false) {
                    this.user_logout();
                } else if (response.data.success === false && response.data.error_code === "1002") {
                    this.$router.push({ name: 'Index' });
                } else {
                    if (this.page === 1
                        && this.query_data.store_code === ""
                        && this.query_data.tax_id === ""
                        && this.query_data.store_name === ""
                        && this.query_data.store_type === ""
                        && this.query_data.status === ""
                        && this.$route.fullPath === this.$route.path
                    ) {
                        this.records = response.data.response_data.data;
                        this.links = response.data.response_data.links;
                        this.current_page = response.data.response_data.current_page;
                        this.last_page = response.data.response_data.last_page;
                    } else {
                        this.$router.push({
                            name: 'StoreList', 
                            query: {
                                page: this.page,
                                store_code: this.query_data.store_code,
                                tax_id: this.query_data.tax_id,
                                store_name: this.query_data.store_name,
                                store_type: this.query_data.store_type,
                                status: this.query_data.status,
                            }
                        });
                        this.records = response.data.response_data.data;
                        this.links = response.data.response_data.links;
                        this.current_page = response.data.response_data.current_page;
                        this.last_page = response.data.response_data.last_page;
                    }  
                }
            })
        },
        qrcode_content(value) {
            this.qrcode_image_content = value;
        },
        download_image() {
            var byteString = atob(this.qrcode_image_content.split(",")[1]);
            var ab = new ArrayBuffer(byteString.length);
            var ia = new Uint8Array(ab);
            for (var i = 0; i < byteString.length; i++) {
                ia[i] = byteString.charCodeAt(i);
            }
            let blob = new Blob([ab], {
                type: "image/png",
            });
            FileSaver.saveAs(blob, this.qrcode_image_file_name);
        },
        store_qrcode(item) {
            this.qrcode_display = false;
            this.qrcode_value = "";
            this.qrcode_image_file_name = "";
            if (item.is_invoice === 1) {
                this.qrcode_display = true;
                this.qrcode_value = item.qrcode_value;
                this.qrcode_image_file_name = item.store_id + "_" + item.store_name + ".png";
            }
        },
        delete_store_data(item) {
            this.delete_data = item;

            const swalWithBootstrapButtons = Swal.mixin({
                customClass: {
                    denyButton: 'm-2 btn btn-danger',
                    cancelButton: 'm-2 btn btn-secondary',
                },
                buttonsStyling: false
            })

            swalWithBootstrapButtons.fire({
                title: '提示訊息',
                html: "確認刪除以下店家資料？<br><br>"
                    + "店家編號： "
                    + item.store_code
                    + "<br>統一編號： "
                    + item.tax_id
                    + "<br>店家名稱： "
                    + item.store_name
                    + "<br>",
                icon: 'warning',
                denyButtonText: '刪除',
                cancelButtonText: '取消',
                showConfirmButton: false,
                showCancelButton: true,
                showDenyButton: true,
                showCloseButton: true,
                allowOutsideClick: false,
            }).then((result) => {
                if (result.isDenied) {
                    this.delete_confirm();
                } else {
                    this.cancel();
                }
            })
        },
        cancel() {
            this.delete_data = {};
        },
        delete_confirm() {
            delete_store(this.delete_data).then(response => {
                if (response.data.success === false && response.data.check === false) {
                    this.user_logout();
                } else {
                    this.delete_data = {};
                    this.search(1);
                    const swalWithBootstrapButtons = Swal.mixin({
                        customClass: {
                            confirmButton: 'm-2 btn btn-primary',
                        },
                        buttonsStyling: false
                    })
                    swalWithBootstrapButtons.fire({
                        title: '提示訊息',
                        text: '刪除成功！',
                        icon: 'success',
                        showCloseButton: true,
                        allowOutsideClick: false,
                        confirmButtonText: '確認',
                    })
                }
            }, () => {
                const swalWithBootstrapButtons = Swal.mixin({
                    customClass: {
                        cancelButton: 'm-2 btn btn-danger'
                    },
                    buttonsStyling: false
                })
                swalWithBootstrapButtons.fire({
                    title: '錯誤訊息',
                    text: '刪除失敗！',
                    icon: 'error',
                    cancelButtonText: '關閉',
                    showCancelButton: true,
                    showConfirmButton: false,
                    showCloseButton: true,
                    allowOutsideClick: false,
                })
            })
        },
        onChangePage(page) {
            const swalWithBootstrapButtons = Swal.mixin({
                customClass: {
                    cancelButton: 'm-2 btn btn-danger'
                },
                buttonsStyling: false
            })
            if (page > this.last_page || page <= 0) {
                swalWithBootstrapButtons.fire({
                    title: '錯誤訊息',
                    text: '頁碼錯誤，無指定頁碼',
                    icon: 'error',
                    cancelButtonText: '關閉',
                    showCancelButton: true,
                    showConfirmButton: false,
                    showCloseButton: true,
                    allowOutsideClick: false,
                })
            } else if (page == this.page) {
                swalWithBootstrapButtons.fire({
                    title: '錯誤訊息',
                    text: '頁碼錯誤，已於指定頁碼',
                    icon: 'error',
                    cancelButtonText: '關閉',
                    showCancelButton: true,
                    showConfirmButton: false,
                    showCloseButton: true,
                    allowOutsideClick: false,
                })
            } else {
                this.search(page, true);
            }
        }
    },
    created() {
        window.scrollTo({top: 0, behavior: "smooth"});
        if (this.$route.query.page !== undefined) {
            if (this.$route.query.store_code !== undefined) {
                this.display_query_data.store_code = this.$route.query.store_code;
            }
            if (this.$route.query.tax_id !== undefined) {
                this.display_query_data.tax_id = this.$route.query.tax_id;
            }
            if (this.$route.query.store_name !== undefined) {
                this.display_query_data.store_name = this.$route.query.store_name;
            }
            if (this.$route.query.store_type !== undefined) {
                this.display_query_data.store_type = this.$route.query.store_type;
            }
            if (this.$route.query.status !== undefined) {
                this.display_query_data.status = this.$route.query.status;
            }
            if (this.$route.query.page == 1) {
                this.search(this.$route.query.page, false);
            } else {
                this.search(this.$route.query.page);
            }
        } else {
            this.search(this.page);
        }
        get_store_type_option().then(response => {
            if (response.data.success === false && response.data.check === false) {
                this.user_logout();
            } else {
                this.store_type_option = response.data.response_data["TW"];
            }
        })
    },
    computed: {
        ...mapGetters({
            role_list: 'role_list'
        }),
    }
};
</script>
